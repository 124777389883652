import image1 from "assets/images/gallery/11.jpg";
import image2 from "assets/images/gallery/2.jpg";
import image3 from "assets/images/gallery/3.jpg";
import image4 from "assets/images/gallery/4.jpg";
import image5 from "assets/images/gallery/5.jpg";
import image6 from "assets/images/gallery/6.jpg";
import image7 from "assets/images/gallery/7.jpg";
import image8 from "assets/images/gallery/8.jpg";
import image9 from "assets/images/gallery/9.jpg";
import image10 from "assets/images/gallery/10.jpg";
import image11 from "assets/images/gallery/11.jpg";
import image12 from "assets/images/gallery/12.jpg";

export default [
  {   
    items: [
      {
        image_url:image1,
        caption: "Naziv",
        description: "Kratak opis",
    
      },
      {
        image_url: image2,
        caption: "Naziv",
        description: "Kratak opis",
       
      },
      {
        image_url:image3,
        caption: "Naziv",
        description: "Kratak opis",
    
      },
      {
        image_url: image4,
        caption: "Naziv",
        description: "Kratak opis",
       
      },
      {
        image_url:image5,
        caption: "Naziv",
        description: "Kratak opis",
    
      },
      {
        image_url: image6,
        caption: "Naziv",
        description: "Kratak opis",
       
      },
      {
        image_url:image7,
        caption: "Naziv",
        description: "Kratak opis",
    
      },
      {
        image_url: image8,
        caption: "Naziv",
        description: "Kratak opis",
       
      },
      {
        image_url:image9,
        caption: "Naziv",
        description: "Kratak opis",
    
      },
      {
        image_url: image10,
        caption: "Naziv",
        description: "Kratak opis",
       
      },
      {
        image_url:image11,
        caption: "Naziv",
        description: "Kratak opis",
    
      },
      {
        image_url: image12,
        caption: "Naziv",
        description: "Kratak opis",
       
      },

    ],
  }
];
